<template>
	<div>
		<template>
			<v-row>
				<v-col cols="6">
					<v-card style="margin: 10px">
						<v-card-title>
							<span class="text-h6">Быстрое создание контакта</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<ckeditor :editor="editor" v-model="editedItem.fast_field"
											:config="editorConfig"></ckeditor>
										<!-- <v-text-field v-model="editedItem.fast_field"></v-text-field> -->
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="save_fast">
								Создать контакт
							</v-btn>
						</v-card-actions>
					</v-card>

					<v-card style="margin: 10px">
						<v-card-title>
							<span class="text-h6">Полное создание контакта</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-row dense>
									<v-col cols="12" sm="6" md="4">
										<v-text-field v-model="editedItem.first_name" label="Имя"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field v-model="editedItem.middle_name" label="Отчество"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field v-model="editedItem.last_name" label="Фамилия"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">

									<v-text-field v-model="editedItem.birth_date" label="YYYY-MM-DD День рождения">
									</v-text-field>

									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field v-model="editedItem.phone_work" label="Раб. номер"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field v-model="editedItem.phone_private" label="Личный номер">
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field v-model="editedItem.email_work" label="Раб. email"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field v-model="editedItem.email_private" label="Личный email">
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field v-model="editedItem.organization" label="Организация">
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field v-model="editedItem.position" label="Должность"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="12">
										<v-text-field v-model="editedItem.skills" label="Навыки"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="12">
										<v-text-field v-model="editedItem.projects_with_us" label="Проекты с нами">
										</v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="12">
										<v-text-field v-model="editedItem.comments" label="Комментарии"></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="save_fast">
								Создать контакт
							</v-btn>
						</v-card-actions>
					</v-card>
					<v-card style="margin: 10px">
						<TagComponent @enableFilter="filterByTagEnable" @disableFilter="filterByTagDisable"/>
					</v-card>
				</v-col>
				<v-col cols="6">
					<v-container>
						<v-chip link href="https://t.me/phonegipsu_bot" target="_blank">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-telegram" viewBox="0 0 16 16">
								<path
									d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
							</svg> 
							&nbsp;
							Telegram bot
						</v-chip>
						<v-chip link href="https://wiki.gip.su/books/phonegipsu" target="_blank">
							<v-icon left>
								mdi-road-variant
							</v-icon>
							Roadmap
						</v-chip>
						<v-chip link href="https://t.me/tehzak" target="_blank">
							<v-icon left>
								mdi-comment-quote-outline
							</v-icon>
							Feedback
						</v-chip>
						<v-chip link :to="{ name: 'calendar'}">
							<v-icon left>
								mdi-calendar
							</v-icon>
							Календарь ДР
						</v-chip>
						

					</v-container>
					<v-card style="margin: 10px">
						<v-card-title>
							<v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
								hide-details>
							</v-text-field>
						</v-card-title>
						<v-data-table :items-per-page="30" dense :headers="headers" :items="phones" :search="search"
							sort-by="id" :sort-desc="true" class="elevation-1">

							<template v-slot:top="{ pagination, options, updateOptions }">

								<!-- Разместить pagination сверху -->
								<v-data-footer :pagination="pagination" :options="options"
									@update:options="updateOptions"
									items-per-page-text="$vuetify.dataTable.itemsPerPageText" />


								<v-toolbar flat>
									<v-toolbar-title>Контакты GIP GROUP</v-toolbar-title>
									<v-divider class="mx-4" inset vertical></v-divider>
									<v-spacer></v-spacer>
									<v-dialog v-model="dialog" max-width="1000px">
										<template v-slot:activator="{ on, attrs }">


											<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
												Добавить контакт
											</v-btn>
											<v-btn color="primary" dark class="mb-2" @click="filterByTagDisable">
												Сбросить фильтр
											</v-btn>
										</template>
										<v-card>
											<v-card-title>
												<span class="text-h5">{{ formTitle }}</span>
											</v-card-title>

											<v-card-text>
												<v-container>
													<v-row>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.first_name" label="Имя">
															</v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.middle_name"
																label="Отчество"></v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.last_name"
																label="Фамилия"></v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">

															<v-text-field v-model="editedItem.birth_date" label="YYYY-MM-DD День рождения">
															</v-text-field>

															</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.phone_work"
																label="Раб. номер"></v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.phone_private"
																label="Личный номер"></v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.email_work"
																label="Раб. email"></v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.email_private"
																label="Личный email"></v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.organization"
																label="Организация"></v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.position"
																label="Должность"></v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.skills" label="Навыки">
															</v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.projects_with_us"
																label="Проекты с нами"></v-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<v-text-field v-model="editedItem.comments"
																label="Комментарии"></v-text-field>
														</v-col>

														<v-col cols="12">
															<ckeditor :editor="editor" v-model="editedItem.fast_field"
																:config="editorConfig">
															</ckeditor>
														</v-col>

													</v-row>
												</v-container>
											</v-card-text>

											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn color="blue darken-1" text @click="close">
													Отменить
												</v-btn>
												<v-btn color="blue darken-1" text @click="save">
													Сохранить
												</v-btn>
											</v-card-actions>
										</v-card>
									</v-dialog>
									<v-dialog v-model="dialogDelete" max-width="500px">
										<v-card>
											<v-card-title class="text-h5">Уверены, что хотите удалить контакт?
											</v-card-title>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn color="blue darken-1" text @click="closeDelete">Отменить</v-btn>
												<v-btn color="blue darken-1" text @click="deleteItemConfirm">Удалить
												</v-btn>
												<v-spacer></v-spacer>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-toolbar>
							</template>

							<!-- <template v-slot:[`item.last_name`]="{ item }">
								<router-link :to="{ name: 'item', params: { id: item.id } }">{{ item.last_name }}</router-link>
							</template> -->

							<!-- <template v-slot:[`item.phone_work`]="{ item }">
								<router-link :to="{ name: 'item', params: { id: item.id } }">{{ item.last_name }}</router-link>
							</template>

							<template v-slot:[`item.phone_private`]="{ item }">
								<router-link :to="{ name: 'item', params: { id: item.id } }">{{ item.last_name }}</router-link>
							</template> -->

							<template v-slot:[`item.info`]="{ item }">
								<p v-if="item.first_name">
									{{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
								</p>
								<p v-if="item.phone_work">
									<a  :href="'tel:' + item.phone_work">{{ item.phone_work }}</a>
								</p>
								<p v-if="item.phone_private">
									<a  :href="'tel:' + item.phone_private">{{ item.phone_private }} </a>
								</p>
								<p v-if="item.email_work">
									<a  :href="'mailto:' + item.email_work">{{ item.email_work }} </a>
									
								</p>
								<p v-if="item.email_private">
									<a   :href="'mailto:' + item.email_private">{{ item.email_private }} </a>
								</p>
								
								<p v-if="item.organization">{{ item.organization }} </p>
								<p v-if="item.position">{{ item.position }} </p>
								<p v-if="item.skills">{{ item.skills }} </p>
								<p v-if="item.projects_with_us">{{ item.projects_with_us }} </p>
								<p v-if="item.comments">{{ item.comments }} </p>
								
								<p v-if="item.birth_date" v-html="linkifyMethod(item.birth_date)"></p>

								

								
							</template>


							<template v-slot:[`item.fast_field`]="{ item }">
								<div v-html="item.fast_field"></div>
								<span v-for="chip in item.tags" v-bind:key = "chip.id">
									<v-chip 
										close
										@click="filterByTagEnable(chip.id)"
										@click:close="removeTag(chip.id, item.id)">
										{{ chip.tag }}
									</v-chip> 
								</span>

								<div>
									<v-autocomplete
									:items="tagsForAutocompete"
									@change="(event) => addTag(event, item.id)"
									></v-autocomplete>
								</div>
							</template>

							<!-- <template v-slot:[`item.responsible_person`]="{ item }">
            <div v-if="item.responsible_person">
              {{ item.responsible_person.first_name }}
              {{ item.responsible_person.last_name }}
            </div>
          </template> -->

							<template v-slot:[`item.actions`]="{ item }">
								<div v-if="user_data.email == 'demo@gip.su'">
									no action permitted
								</div>
								<div v-else>
									<router-link :to="{ name: 'phone', params: { id:  item.id} }" >
										<v-icon small class="mr-2">
										mdi-link
									</v-icon>
    								</router-link>

									<v-icon small class="mr-2" @click="editItem(item)">
										mdi-pencil
									</v-icon>

									<v-icon small @click="deleteItem(item)">
										mdi-delete
									</v-icon>
								</div>

							</template>
							<template v-slot:no-data>
								<v-btn color="primary" @click="initialize">
									Reset
								</v-btn>
							</template>
						</v-data-table>
					</v-card>
				</v-col>
			</v-row>

		</template>
		<v-footer color="red" padless>
			<v-card class="flex" flat tile>
				<v-card-text class="py-2 text-center">
					{{ new Date().getFullYear() }} — <strong>powered by GIP GROUP</strong> <br>
					<a href='https://gip-group.su'><strong>https://gip-group.su</strong></a>
				</v-card-text>
			</v-card>
		</v-footer>
	</div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-weblineindia-qrcode';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import TagComponent from './Tags.vue'

export default {
	components: {
		VueQrCode,
		TagComponent
	},
	computed: {
		user() {
			return this.$store.state.authentication.user;
		},
		users() {
			return this.$store.state.users.all;
		},
		formTitle() {
			return this.editedIndex === -1 ? 'Новый контакт' : 'Отредактировать контакт'
		},
		tagsForAutocompete(){
			let res = []
			for (var i=0; i<this.tags.length; i++) {
				let tag_name = this.tags[i].tag;
				res.push(tag_name)
			}
			return res
		}
	},

	data: () => ({
		phones: [],
		phones_initial_list: [],
		phones_filtered_list: [],
		search: '',
		dialog: false,
		dialogDelete: false,
		headers: [
			// {
			// 	text: 'id',
			// 	sortable: true,
			// 	filterable: true,
			// 	value: 'id',
			// },
			{ text: 'Сведения о контакте', value: 'info', sortable: false, },

			{ text: 'Имя', value: 'first_name', align: ' d-none' },
			{ text: 'Отчество', value: 'middle_name', align: ' d-none' },
			{ text: 'Фамилия', value: 'last_name', align: ' d-none' },
			{ text: 'Раб. номер', value: 'phone_work', align: ' d-none' },
			{ text: 'Личный номер', value: 'phone_private', align: ' d-none' },
			{ text: 'Раб. email', value: 'email_work', align: ' d-none' },
			{ text: 'Личный email', value: 'email_private', align: ' d-none' },
			{ text: 'Организация', value: 'organization', align: ' d-none' },
			{ text: 'Должность', value: 'position', align: ' d-none' },
			{ text: 'Навыки', value: 'skills', align: ' d-none' },
			{ text: 'Проекты с нами', value: 'projects_with_us', align: ' d-none' },
			{ text: 'Комментарии', value: 'comments', align: ' d-none' },

			{ text: 'Быстрое поле', value: 'fast_field', sortable: false, },

			{ text: 'Действия', value: 'actions', sortable: false },
		],
		editedIndex: -1,
		editedItem: {
			id: '',
			first_name: '',
			middle_name: '',
			last_name: '',
			phone_work: '',
			phone_private: '',
			email_work: '',
			email_private: '',
			organization: '',
			position: '',
			skills: '',
			projects_with_us: '',
			comments: '',
			birth_date: '',
			tags: '',
			fast_field: '',
		},
		defaultItem: {
			id: '',
			first_name: '',
			middle_name: '',
			last_name: '',
			phone_work: '',
			phone_private: '',
			email_work: '',
			email_private: '',
			organization: '',
			position: '',
			skills: '',
			projects_with_us: '',
			comments: '',
			birth_date: '',
			tags: '',
			fast_field: '',
		},
		user_data: {},


		editor: ClassicEditor,
		editorConfig: {
		},

		tags: [],

	}),
	watch: {
		dialog(val) {
			val || this.close()
		},
		dialogDelete(val) {
			val || this.closeDelete()
		},
	},

	created() {
		window.location.href='https://lk2.gip.su/gip/phone/phonepage'
		this.initialize()
	},

	methods: {
		initialize() {


			let user = JSON.parse(localStorage.getItem('user'));
			this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
			let self = this
			this.$axios
				.get(process.env.VUE_APP_BACKENDAPI + 'v1/phones/')
				.then(function (response) {
					self.phones = response.data

					// Сделаем НЕреактиную копию для будущего снятия фильтрации
					self.phones_initial_list = JSON.parse(JSON.stringify(self.phones))

				});
			
			


			let _user = JSON.parse(localStorage.getItem('user'));

			if (_user) {
				let self = this;
				this.$axios.get(process.env.VUE_APP_BACKENDAPI + 'get_current_user/' + _user.access)
					.then(function (response) {
						localStorage.setItem('user_data', JSON.stringify(response.data));
						self.user_data = JSON.parse(localStorage.getItem('user_data'))
					});
			}

			this.$axios
				.get(process.env.VUE_APP_BACKENDAPI + 'v1/tags/')
				.then(function (response) {
					self.tags = response.data
				});

		},

		editItem(item) {
			this.editedIndex = this.phones.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true

		},

		deleteItem(item) {
			this.editedIndex = this.phones.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},

		deleteItemConfirm() {
			this.phones.splice(this.editedIndex, 1)

			let user = JSON.parse(localStorage.getItem('user'));
			let self = this;
			this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
			this.$axios.delete(process.env.VUE_APP_BACKENDAPI + 'v1/phones/' + this.editedItem.id + '/').then(function (response) {
				self.initialize()
				self.$notify({
					title: 'Успешно',
					text: 'Данные контакта удалены',
					type: 'warning',
					duration: 5000,
				});
				self.close()
			});

			this.closeDelete()
		},

		close() {
			this.dialog = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},

		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},
		save_fast() {
			// создание объекта
			let user = JSON.parse(localStorage.getItem('user'));
			let self = this;
			if (this.editedItem.birth_date == "") {
				this.editedItem.birth_date = null;
			}
			this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
			this.$axios.post(process.env.VUE_APP_BACKENDAPI + 'v1/phones/',
				{
					first_name: this.editedItem.first_name,
					middle_name: this.editedItem.middle_name,
					last_name: this.editedItem.last_name,
					phone_work: this.editedItem.phone_work,
					phone_private: this.editedItem.phone_private,
					email_work: this.editedItem.email_work,
					email_private: this.editedItem.email_private,
					organization: this.editedItem.organization,
					position: this.editedItem.position,
					skills: this.editedItem.skills,
					projects_with_us: this.editedItem.projects_with_us,
					comments: this.editedItem.comments,
					birth_date: this.editedItem.birth_date,

					fast_field: this.editedItem.fast_field,
				},
				{
				}).then(function (response) {
					self.initialize()
					self.$notify({
						title: 'Успешно',
						text: 'Контакт успешно создан',
						type: 'success',
						duration: 5000,
					});
					self.close()
				}).catch((error) => {
					if (error.response) {
						self.$notify({
							title: 'Ошибка',
							text: error.response.data,
							type: 'error',
							duration: 5000,
						});
					}
				});;

		},
		save() {
			if (this.editedIndex > -1) {
				// изменение объекта

				let user = JSON.parse(localStorage.getItem('user'));
				let self = this;
				this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
				this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/phones/' + this.editedItem.id + '/',
					{
						first_name: this.editedItem.first_name,
						middle_name: this.editedItem.middle_name,
						last_name: this.editedItem.last_name,
						phone_work: this.editedItem.phone_work,
						phone_private: this.editedItem.phone_private,
						email_work: this.editedItem.email_work,
						email_private: this.editedItem.email_private,
						organization: this.editedItem.organization,
						position: this.editedItem.position,
						skills: this.editedItem.skills,
						projects_with_us: this.editedItem.projects_with_us,
						comments: this.editedItem.comments,
						birth_date: this.editedItem.birth_date,

						fast_field: this.editedItem.fast_field,
					},
					{
					}).then(function (response) {
						self.initialize()
						self.$notify({
							title: 'Успешно',
							text: 'Данные контакта обновлены',
							type: 'success',
							duration: 5000,
						});
						self.close()
					});

			} else {

				// создание объекта
				let user = JSON.parse(localStorage.getItem('user'));
				let self = this;
				this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
				this.$axios.post(process.env.VUE_APP_BACKENDAPI + 'v1/phones/',
					{
						first_name: this.editedItem.first_name,
						middle_name: this.editedItem.middle_name,
						last_name: this.editedItem.last_name,
						phone_work: this.editedItem.phone_work,
						phone_private: this.editedItem.phone_private,
						email_work: this.editedItem.email_work,
						email_private: this.editedItem.email_private,
						organization: this.editedItem.organization,
						position: this.editedItem.position,
						skills: this.editedItem.skills,
						projects_with_us: this.editedItem.projects_with_us,
						comments: this.editedItem.comments,
						birth_date: this.editedItem.birth_date,

						fast_field: this.editedItem.fast_field,
					},
					{
					}).then(function (response) {
						self.initialize()
						self.$notify({
							title: 'Успешно',
							text: 'Контакт успешно создан',
							type: 'success',
							duration: 5000,
						});
						self.close()
					}).catch((error) => {
						if (error.response) {

							self.$notify({
								title: 'Ошибка',
								text: error.response.data,
								type: 'error',
								duration: 5000,
							});
						}
					});;

			}
			this.close()
		},
		linkifyMethod(value) {
			return moment(String(value)).format('DD.MM.YYYY')
			},

		addTag(tag_name, phone_id){

			let index = this.tags.findIndex(function(item, i){
				return item.tag === tag_name
			});

			let tag_that_found = this.tags[index]
		
			let index2 = this.phones.findIndex(function(item, i){
				return item.id === phone_id
			});

			let phone_that_found = this.phones[index2]

			// удалим их листа найденный тег
			let new_tags_list = phone_that_found.tags.slice();
			new_tags_list.push(tag_that_found)


			//сделаем лист pk
			let new_tags_list_pk = []
			for (var i=0; i<new_tags_list.length; i++) {
				let tg_id = new_tags_list[i].id;
				new_tags_list_pk.push(tg_id)
			}


			let user = JSON.parse(localStorage.getItem('user'));
			let self = this;
			this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
			this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/phones/' + phone_id + '/',
				{
					tags: new_tags_list_pk,
					tag_action: 'removetag',
				},
				{
				}).then(function (response) {
					self.initialize()
					self.$notify({
						title: 'Успешно',
						text: 'Данные контакта обновлены',
						type: 'success',
						duration: 5000,
					});
					self.close()
				});
		},

		removeTag(tag_id, phone_id){


			let index = this.tags.findIndex(function(item, i){
				return item.id === tag_id
			});

			let tag_that_found = this.tags[index]
		
			let index2 = this.phones.findIndex(function(item, i){
				return item.id === phone_id
			});

			let phone_that_found = this.phones[index2]

			// удалим их листа найденный тег
			let new_tags_list = phone_that_found.tags.slice();
			for( var i = 0; i < new_tags_list.length; i++){ 
				if ( new_tags_list[i].id == tag_that_found.id ) { 
					new_tags_list.splice(i, 1); 
				}
			}


			//сделаем лист pk
			let new_tags_list_pk = []
			for (var i=0; i<new_tags_list.length; i++) {
				let tag_id = new_tags_list[i].id;
				new_tags_list_pk.push(tag_id)
			}


			let user = JSON.parse(localStorage.getItem('user'));
			let self = this;
			this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
			this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/phones/' + phone_id + '/',
				{
					tags: new_tags_list_pk,
					tag_action: 'removetag',
				},
				{
				}).then(function (response) {
					self.initialize()
					self.$notify({
						title: 'Успешно',
						text: 'Данные контакта обновлены',
						type: 'success',
						duration: 5000,
					});
					self.close()
				});
			},
		
		filterByTagEnable(tag_id){
			console.log('filterByTagEnable parent', tag_id)

			let filtered_phone_list = [];


			for (var i=0; i<this.phones_initial_list.length; i++) {

				for (var j=0; j<this.phones_initial_list[i].tags.length; j++) {
					if (this.phones_initial_list[i].tags[j].id == tag_id){
						filtered_phone_list.push(this.phones_initial_list[i])
					}
				}
			}

			this.phones = filtered_phone_list

		},
		filterByTagDisable(){
			console.log('filterByTagDisable parent')

			this.phones = JSON.parse(JSON.stringify(this.phones_initial_list))
		},


		},


	filters: {
		formatDate: function (value) {
			if (value) {
				return moment(String(value)).format('HH:mm - DD.MM.YYYY')
			}
		},
	}
}
</script>

