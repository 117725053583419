<template>
	<div>
		<template>

			<v-card 
			style="margin: 20px"
	if='phone'
    class="mx-auto"
    max-width="500"
  >
    <v-card-text >
      <div>Информация о контакте</div>
      <p v-if="phone.first_name" class="text-h4 text--primary">
        {{ phone.first_name }} {{ phone.middle_name }} {{ phone.last_name }}
      </p>
	  <div v-if="phone.phone_work" class="text--primary">
        {{ phone.phone_work }}
      </div>
	  <div v-if="phone.phone_private" class="text--primary">
        {{ phone.phone_private }}
      </div>
	  <div v-if="phone.email_work" class="text--primary">
        {{ phone.email_work }}
      </div>
	  <div v-if="phone.email_private" class="text--primary">
        {{ phone.email_private }}
      </div>
	  <div v-if="phone.organization" class="text--primary">
        {{ phone.organization }}
      </div>
	  <div v-if="phone.position" class="text--primary">
        {{ phone.position }}
      </div>
	  <div v-if="phone.skills" class="text--primary">
        {{ phone.skills }}
      </div>
	  <div v-if="phone.projects_with_us" class="text--primary">
        {{ phone.projects_with_us }}
      </div>
	  <div v-if="phone.comments" class="text--primary">
        {{ phone.comments }}
      </div>

	  <div class="text--primary">
        <p v-if="phone.birth_date" v-html="linkifyMethod(phone.birth_date)"></p>
      </div>
	  <div class="text--primary" v-html="phone.fast_field">
      </div>
    </v-card-text>



  </v-card>	
		</template>
		<v-footer color="red" padless>
			<v-card class="flex" flat tile>
				<v-card-text class="py-2 text-center">
					{{ new Date().getFullYear() }} — <strong>powered by GIP GROUP</strong> <br>
					<a href='https://gip-group.su'><strong>https://gip-group.su</strong></a>
				</v-card-text>
			</v-card>
		</v-footer>
	</div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-weblineindia-qrcode';

export default {
	components: {
		VueQrCode,
	},
	computed: {
		user() {
			return this.$store.state.authentication.user;
		},
		users() {
			return this.$store.state.users.all;
		},
	},

	data: () => ({
		phone: {},
		user_data: {},

	}),


	created() {
        window.location.href='https://lk2.gip.su/gip/phone/phones/'+this.$route.params.id

		this.initialize()
	},

	methods: {
		initialize() {
			let user = JSON.parse(localStorage.getItem('user'));
			this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
			let self = this
			this.$axios
				.get(process.env.VUE_APP_BACKENDAPI + 'v1/phones/' + self.$route.params.id)
				.then(function (response) {
					self.phone = response.data
				});

			let _user = JSON.parse(localStorage.getItem('user'));

			if (_user) {
				let self = this;
				this.$axios.get(process.env.VUE_APP_BACKENDAPI + 'get_current_user/' + _user.access)
					.then(function (response) {
						localStorage.setItem('user_data', JSON.stringify(response.data));
						self.user_data = JSON.parse(localStorage.getItem('user_data'))
					});
			}


		},
		linkifyMethod(value) {
			return moment(String(value)).format('DD.MM.YYYY')
		},
	},
	filters: {
		formatDate: function (value) {
			if (value) {
				return moment(String(value)).format('HH:mm - DD.MM.YYYY')
			}
		},
	}
}
</script>

