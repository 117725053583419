<template>
	<div>
		<template>

			<v-card 
			style="margin: 20px"
                if='phones'
                class="mx-auto"
                max-width="500"
                v-for="phone in phones" :key="phone.id"
                >
        <v-card-text >
            <span v-if="phone.get_monthname" class="text-h6 text--primary">
                {{ phone.get_monthname }}
                <router-link :to="{ name: 'phone', params: { id:  phone.id} }" >
                    
                    {{ phone.first_name }} {{ phone.middle_name }} {{ phone.last_name }} , 
                    <span v-if="phone.birth_date" v-html="linkifyMethod(phone.birth_date)"></span>

                </router-link>
                <div class="text--primary" v-html="phone.fast_field">
                </div>
            </span>
        </v-card-text>

  </v-card>	
		</template>
		<v-footer color="red" padless>
			<v-card class="flex" flat tile>
				<v-card-text class="py-2 text-center">
					{{ new Date().getFullYear() }} — <strong>powered by GIP GROUP</strong> <br>
					<a href='https://gip-group.su'><strong>https://gip-group.su</strong></a>
				</v-card-text>
			</v-card>
		</v-footer>
	</div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-weblineindia-qrcode';

export default {
	components: {
		VueQrCode,
	},
	computed: {
		user() {
			return this.$store.state.authentication.user;
		},
		users() {
			return this.$store.state.users.all;
		},
	},

	data: () => ({
		phones: [],
		user_data: {},

	}),


	created() {
        window.location.href='https://lk2.gip.su/gip/phone/calendar'
		this.initialize()
	},

	methods: {
		initialize() {
			let user = JSON.parse(localStorage.getItem('user'));
			this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
			let self = this
			this.$axios
				.get(process.env.VUE_APP_BACKENDAPI + 'v1/phones/calendar')
				.then(function (response) {
					self.phones = response.data
				});

			let _user = JSON.parse(localStorage.getItem('user'));

			if (_user) {
				let self = this;
				this.$axios.get(process.env.VUE_APP_BACKENDAPI + 'get_current_user/' + _user.access)
					.then(function (response) {
						localStorage.setItem('user_data', JSON.stringify(response.data));
						self.user_data = JSON.parse(localStorage.getItem('user_data'))
					});
			}


		},
		linkifyMethod(value) {
			return moment(String(value)).format('DD.MM.YYYY')
		},
	},
	filters: {
		formatDate: function (value) {
			if (value) {
				return moment(String(value)).format('HH:mm - DD.MM.YYYY')
			}
		},
	}
}
</script>

