import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('div',[_c(VToolbar,{attrs:{"blue":""}},[_c(VToolbarTitle,[_c('router-link',{attrs:{"to":{ name: 'phones' }}},[_vm._v("Контакты GIP GROUP")])],1),_c(VSpacer),(_vm.user_data)?_c(VToolbarTitle,[_vm._v(_vm._s(_vm.user_data.first_name)+" "+_vm._s(_vm.user_data.last_name))]):_vm._e(),(_vm.user_data)?_c(VBtn,{attrs:{"icon":""}},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":"/login"}},[_c(VIcon,[_vm._v("mdi-export")])],1)],1):_vm._e()],1)],1),_c('div',{},[_c('div',{},[_c('div',{},[(_vm.alert.message)?_c('div',{class:`alert ${_vm.alert.type}`},[_vm._v(_vm._s(_vm.alert.message))]):_vm._e(),_c('router-view')],1)])]),_c('notifications',{attrs:{"position":"bottom right"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }