<template>
    <div>
404. Страница не найдена
    </div>
</template>

<script>

export default {
    
}
</script>
