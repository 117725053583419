<template>
    <v-data-table
      :headers="headers"
      :items="tags"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Создание тега</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Новый тег
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="editedItem.tag"
                        label="Тег"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="filterByTagEnable(item.id)"
        >
          mdi-filter
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="filterByTagDisable()"
        >
          mdi-filter-remove
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </template>
<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Теги', value: 'tag' },
      { text: 'Действия', value: 'actions', sortable: false },
    ],
    
    tags: [],

    editedIndex: -1,
    editedItem: {
      tag: '',
    },
    defaultItem: {
      tag: '',
    },
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Новый тег' : 'Откорректировать'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {

        let user = JSON.parse(localStorage.getItem('user'));
        this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
        let self = this
        this.$axios
            .get(process.env.VUE_APP_BACKENDAPI + 'v1/tags/')
            .then(function (response) {
                self.tags = response.data
            });

    },

    editItem (item) {
      this.editedIndex = this.tags.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.tags.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.tags.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
			if (this.editedIndex > -1) {
				// изменение объекта

				let user = JSON.parse(localStorage.getItem('user'));
				let self = this;
				this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
				this.$axios.patch(process.env.VUE_APP_BACKENDAPI + 'v1/tags/' + this.editedItem.id + '/',
					{
						tag: this.editedItem.tag,
					},
					{
					}).then(function (response) {
						self.initialize()
						self.$notify({
							title: 'Успешно',
							text: 'Данные контакта обновлены',
							type: 'success',
							duration: 5000,
						});
						self.close()
					});

			} else {

				// создание объекта
				let user = JSON.parse(localStorage.getItem('user'));
				let self = this;
				this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access;
				this.$axios.post(process.env.VUE_APP_BACKENDAPI + 'v1/tags/',
					{
						tag: this.editedItem.tag,
					},
					{
					}).then(function (response) {
						self.initialize()
						self.$notify({
							title: 'Успешно',
							text: 'Контакт успешно создан',
							type: 'success',
							duration: 5000,
						});
						self.close()
					}).catch((error) => {
						if (error.response) {

							self.$notify({
								title: 'Ошибка',
								text: error.response.data,
								type: 'error',
								duration: 5000,
							});
						}
					});;

			}
			this.close()
		},
    filterByTagEnable(tag_id){
        console.log('filterByTagEnable child', tag_id)
        this.$emit("enableFilter", tag_id)
    },
    filterByTagDisable(){
        console.log('filterByTagDisable child')
        this.$emit("disableFilter")
    },
  },
}
</script>
