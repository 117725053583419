import Vue from 'vue';
import Router from 'vue-router';

import PhonesPage from '../business/PhonesPage.vue'
import PhonePage from '../business/Phone.vue'
import CalendarPage from '../business/Calendar.vue'
import NotFound from '../business/NotFound.vue'

import LoginPage from '../login/LoginPage.vue'
NotFound

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [{
      path: '',
      component: PhonesPage,
      name: 'phones',
    },
    { 
      path: '/phone/:id', 
      component: PhonePage,
      name: 'phone',
    },
    { 
      path: '/calendar', 
      component: CalendarPage,
      name: 'calendar',
    },
    {
      path: '/login',
      component: LoginPage,
      redirect: to => {
      },
    },
    {
      path :'*',
      component: NotFound
  }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})